import { Platform, PlatformType } from 'apps/acp/packages/acp-config';
import { getAcpConfig } from './global-acp';

export const overrideConfiguration = (
  platform: Platform,
  platformType: PlatformType,
  version: string
) => {
  const { config } = getAcpConfig();
  config.platform = platform;
  config.platformType = platformType;
  config.version = version;
};
