import {
  BehaviorSubject,
  createBehaviorSubject
} from 'packages/behavior-subject';
import {
  createFetcher,
  Fetcher,
  FetcherRefreshPolicy,
  FetchImpl
} from 'packages/http-client/fetcher';
import { AcpConfig, AcpEnvironment } from 'apps/acp/packages/acp-config';

// decorator: base url
// decorator: X-NS-Client header
// decorator: unfetch testing override
// decorator: access token
// query refetch check
// dispatch new fetcher on access token change

// I defined a middleware type for chain of responbility pattern
// with fetch (like express js middleware).
type FetcherDecorator = (next: FetchImpl) => FetchImpl;

// I am a type-safe pipe functional utility.
const pipe = <T extends any[], R>(
  fn1: (...args: T) => R,
  ...fns: Array<(a: R) => R>
) => {
  const piped = fns.reduce(
    (prevFn, nextFn) => (value: R) => nextFn(prevFn(value)),
    (value) => value
  );
  return (...args: T) => piped(fn1(...args));
};

// I take the ACP config and encode the value used in the request
// header for webapi.
function buildNsClientHeaderValue(config: AcpConfig) {
  return [
    `app=${config.appName}`,
    `platform=${config.platform}`,
    `platformType=${config.platformType}`,
    `brand=${config.brand}`,
    `version=${config.version}`
  ]
    .join('; ')
    .trim();
}

// I apply the base url to every request URL to reduce brittle api resource
// links throughout the app.
const withBaseUrl = (baseUrl: string): FetcherDecorator => (next) => (
  url,
  requestConfig
) => next(baseUrl + url, requestConfig);

// I include the x-ns-client and x-ns-variant header in all requests with the passed config.
const withNsClientHeader = (config: AcpConfig): FetcherDecorator => (next) => (
  url,
  requestConfig
) =>
  next(url, {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      'X-NS-Client': buildNsClientHeaderValue(config),
      'X-NS-Variant': `variant://${config.variant}`
    }
  });

// I include the access token if it exists in headers.
const withAccessToken = (): FetcherDecorator => (next) => (
  url,
  requestConfig
) => {
  const KEY = 'acp_access_token';
  let accessToken = '';
  try {
    const token = sessionStorage.getItem(KEY) || '';
    accessToken = JSON.parse(window.atob(token));
  } catch (err) {
    // noop
  }
  return next(url, {
    ...requestConfig,
    headers: {
      ...(accessToken ? { 'X-NS-Access_Token': accessToken } : null),
      ...requestConfig.headers
    }
  });
};

// I mock out fetch with an XHR implementation so Cypress can intercept
// requests.
const withMaybeMockFetch = (shouldMock: boolean): FetcherDecorator => (
  next
) => async (url, requestConfig) => {
  let fetchImpl = next;
  if (shouldMock) {
    // @ts-ignore: We don't want TS to have to get the types for unfetch, but it will be validated at bundle-time
    const unfetch = await import('unfetch');
    fetchImpl = unfetch.default;
  }
  return fetchImpl(url, requestConfig);
};

// I decide when fetcher queries should invalidate cache and refresh.
const fetcherRefreshPolicy: FetcherRefreshPolicy = ([completedRequest]) => {
  const completedRequestOpts: any = completedRequest.opts || {};
  const shouldInvalidate =
    !!completedRequest.mutating && !completedRequestOpts.updatesAccessToken;

  return shouldInvalidate;
};

// I create the fetcher instance with applied decorators.
function createWebapiFetcher(acpEnvironment: AcpEnvironment): Fetcher {
  // Each request/response will be piped, top-down, through the decorators.
  const fetchDecorator = pipe(
    withMaybeMockFetch(localStorage.getItem('useFetchPolyfill') === 'yes'),
    withNsClientHeader(acpEnvironment.config),
    withAccessToken(),
    withBaseUrl('/webapi')
  );
  return createFetcher(fetchDecorator(window.fetch), fetcherRefreshPolicy);
}

// I provide a mechanism for updating fetcher instance. This is valuable
// as it allows us to rely on busting cache by re-freshing the fetcher
// and triggering a full react render by updating context. This is very
// useful when changing authentication levels (i.e. logout).
export function webapiFetcherBehaviorSubjectFactory({
  acpEnvironment
}: {
  acpEnvironment: AcpEnvironment;
}): BehaviorSubject<Fetcher> {
  const initialFetcher = createWebapiFetcher(acpEnvironment);
  const [dispatch, addListener] = createBehaviorSubject(initialFetcher);

  return [dispatch, addListener];
}
