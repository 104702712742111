import { getAcpConfig } from './global-acp';

declare global {
  interface Window {
    cordova: any;
  }
}
export const getNativeDeviceVersion = () => {
  return Promise.resolve()
    .then(() => window.cordova.getAppVersion.getVersionNumber())
    .catch(() => getAcpConfig().config.version);
};
