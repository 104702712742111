// import { DeeplinkData } from 'core';

const win = window as any;

export const setupDeeplinking = () => {
  if (win.plugins && win.plugins.appsFlyer && win.IonicDeeplink) {
    win.IonicDeeplink.onDeepLink((data: any) => {
      win.deeplinkData = data;
      win.plugins.appsFlyer.handleOpenUrl(data.url);
    });
  }
};
