import { isAndroid, isIOS } from 'packages/platform-detector';
import { AcpEnvironment, Platform } from 'apps/acp/packages/acp-config';
import { CordovaGlobal } from 'apps/acp/packages/environment';

const derivePlatform = (): Platform => {
  if (isIOS()) {
    return 'ios';
  }
  if (isAndroid()) {
    return 'android';
  }
  return 'web';
};

let setAcpEnv: (config: AcpEnvironment) => void;
const acpEnvPromise = new Promise<AcpEnvironment>(
  (resolve) => (setAcpEnv = resolve)
);
/** This should only be called by the gateway bootstrap, not used to *update* config */
export const setPrivateAcpEnvOnce = (env: AcpEnvironment) => {
  if (!setAcpEnv) {
    throw new Error('Cannot update acp env');
  }
  setAcpEnv(env);
};

export const createAcpEnvironment = async (cordova?: CordovaGlobal) => {
  const env = await acpEnvPromise;
  if (cordova) {
    env.config.platform = derivePlatform();
    env.config.platformType = 'cordova';
    try {
      env.config.version = await cordova.getAppVersion.getVersionNumber();
    } catch (err) {
      // noop, this error, just leave the version alone
    }
  }
  return env;
};
