import {
  BehaviorSubjectSubscribe,
  createBehaviorSubject
} from 'packages/behavior-subject';
import { Fetcher, RequestConfig } from 'packages/http-client/fetcher';
import {
  createFetcherPermissionsResolver,
  PermissionResolver
} from 'packages/permissions/permission-resolver';

const createWebapiFeatureRequest = (features: string[]): RequestConfig => ({
  method: 'POST',
  url: '/v2/features',
  body: JSON.stringify({
    features
  }),
  headers: {
    'content-type': 'application/json'
  }
});

function createWebapiPermissionResolver(fetcher: Fetcher) {
  return createFetcherPermissionsResolver(
    fetcher,
    createWebapiFeatureRequest,
    (response: Response, data: any) => {
      if (data && data.features) {
        return data.features;
      }

      return {};
    },
    (feature: string) => feature.toLowerCase()
  );
}

export function webapiPermissionResolverSubscribeFactory({
  fetcherSubscribe
}: {
  fetcherSubscribe: BehaviorSubjectSubscribe<Fetcher>;
}) {
  const [dispatch, subscribe] = createBehaviorSubject<
    PermissionResolver<string>
  >(null as any);
  fetcherSubscribe((fetcher) => {
    const permissionResolver = createWebapiPermissionResolver(fetcher);
    dispatch(permissionResolver);
  });

  return subscribe;
}

export const createDeclarativePermissionApi = <P extends string>(
  resolverSubscribe: BehaviorSubjectSubscribe<PermissionResolver<string>>
) => async <R extends P>(permissions: { [perm in R]: boolean }) => {
  let resolvedPermissionsPromise: Promise<Record<string, boolean>>;
  let unsubscribe = (): void => void 0;
  const permissionNames = Object.keys(permissions) as R[];

  try {
    // First try to use the built-in angularjs NsPermissions service, since that
    // will be kept in-sync
    resolvedPermissionsPromise = (window as any).angular
      .element(document)
      .injector()
      .get('nsPermissions')
      .requestPermissions(permissionNames);
  } catch (err) {
    // Fallback to the PermissionResolver which is kept in-sync via the fetcher
    resolvedPermissionsPromise = new Promise((resolve, reject) => {
      const resolverUnsubscribe = resolverSubscribe((resolver) => {
        unsubscribe = resolver(
          permissionNames,
          ([loading, error, resolvedPermissions]) => {
            if (error) {
              reject(error);
            } else if (!loading && resolvedPermissions) {
              resolve(resolvedPermissions as Record<string, boolean>);
            }
          }
        );
      });
      resolverUnsubscribe();
    });
  }

  try {
    const resolvedPermissions = await resolvedPermissionsPromise;
    return permissionNames.every(
      (perm) => resolvedPermissions[perm] === permissions[perm]
    );
  } finally {
    // Unsubscribe from our permission resolver before exiting
    unsubscribe();
  }
};
