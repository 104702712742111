//
// Create all Injectable Services
//
import { createInjectable } from 'packages/gateway/gateway';
import { createAcpEnvironment } from './environment';
import {
  createDeclarativePermissionApi,
  webapiPermissionResolverSubscribeFactory
} from './permissions';
import { getCordovaIfApplicable } from './util/cordova';
import { webapiFetcherBehaviorSubjectFactory } from './webapi-fetcher';
import { isPreOnboardingRedirectionNeeded } from 'apps/acp/packages/pre-onboarding-eligibility';

export const cordova = createInjectable(() => () => getCordovaIfApplicable());

export const acpEnvironment = createInjectable(
  // tslint:disable-next-line: no-shadowed-variable
  () => ({ cordova }) => createAcpEnvironment(cordova),
  {
    cordova
  }
);

export const webapiFetcherBehaviorSubject = createInjectable(
  () => webapiFetcherBehaviorSubjectFactory,
  { acpEnvironment }
);

export const webapiFetcher = createInjectable(
  () => (deps) => deps.webapiFetcherBehaviorSubject[1],
  { webapiFetcherBehaviorSubject }
);

export const webapiPermissionResolverSubscribe = createInjectable(
  () => webapiPermissionResolverSubscribeFactory,
  { fetcherSubscribe: webapiFetcher }
);

export const declarativePermissionApi = createInjectable(
  () => ({ permissionsResolverSubscribe }) =>
    createDeclarativePermissionApi(permissionsResolverSubscribe),
  {
    permissionsResolverSubscribe: webapiPermissionResolverSubscribe
  }
);

export const isPreOnboardingEligible = createInjectable(
  () => async ({ cordova, acpEnvironment, permissions }) => {
    const isPreOnboardingEnabled = acpEnvironment.config.preOnboardingEnabled;
    return await isPreOnboardingRedirectionNeeded(
      isPreOnboardingEnabled,
      permissions,
      cordova
    );
  },
  { cordova, acpEnvironment, permissions: declarativePermissionApi }
);
