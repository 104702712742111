type ReturnFunc<T> = () => T;

export const once = <T>(func: ReturnFunc<T>): ReturnFunc<T> => {
  let result: T;
  return () => {
    if (func) {
      result = func();
      (func as any) = null;
    }
    return result;
  };
};
