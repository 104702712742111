// Permissions that we want readily available. This makes our transitions
// between MFEs and Angular faster by not waiting for this to eval, we
// can jump to the MFE right away
const permissions = [
  'acp:rising_tide_eligible',
  'AH:isDistributorAdjustmentHubConfigured',
  'acp:login_mfe_eligible',
  'webapi:spending_tracker',
  'webapi:file_lean_dispute_enabled',
  'acp:security_mfe_eligible',
  'acp:preonboarding_mfe_eligible',
  'acp:notifications_mfe_eligible'
];

export const $configPreloadNgPermissions = () => {
  // We use a setTimeout to run immediately after the config blocks run (they can't be async)
  // We don't have a way to register a run block from a config block, and we dont have a module of course
  setTimeout(() => {
    const nsPermissions: any = (window as any).angular
      .element(document)
      .injector()
      .get('nsPermissions');
    const refresh = () => nsPermissions.requestPermissions(permissions);
    // We fetch them right off the bat, and refresh them when we are cleared
    nsPermissions.cleared.onValue(refresh);
    refresh();
  });
};
