/* eslint-disable no-console */
// We register a fallback that ensures that if no mfes are loaded,
// we still load the angular app. If all MFEs were accounted for
// this will not happen, but we have to be sure to show the angular

import {
  addGatewayListener,
  baseUrl,
  MfeFactory
} from 'packages/gateway/gateway';
import { Link } from 'packages/links';
import { doBootstrap } from '../bootstrap';
import { createNgFallbackHandler } from './ng-fallback-handler';
import { once } from './once';
import { $configPreloadNgPermissions } from './preload-ng-permissions';
import { deleteCookie } from './util/cookie-helper';

const fallbackHandler = createNgFallbackHandler(baseUrl.extend`/login`());

const bootstrapNg = once(async () =>
  doBootstrap([fallbackHandler, $configPreloadNgPermissions])
);

// This will be set when we are starting to leave the angular application
// to go to the MFEs
let isLeavingAngularApp = false;

// Helper to toggle the angular apps visibility.
// When the app is not visible, the loader is shown.
const setAngularVisible = (visible: boolean) => {
  const ldr = document.getElementById('app-loader');
  if (ldr && ldr.dataset.initialLoad === 'true') {
    ldr.style.display = visible ? 'none' : '';
  }
};

// Listen for the start of url changes, hide the ng app
addGatewayListener('urlChangeStarted', () => setAngularVisible(false));
// Listen for when the URL is settled, show the ng app
addGatewayListener('urlChangeCompleted', ({ activeMfes, location }) => {
  if (!isLeavingAngularApp) setAngularVisible(true);

  // Bootstrap angular even if no MFEs became active. This indicates that we have urls that do not
  // belong to any mfe
  if (activeMfes.length === 0) {
    // tslint:disable-next-line: no-console
    console.warn(
      `URL change finished without any MFEs loaded: ${location.pathname}`
    );
    bootstrapNg();
  }
});
// Listen for then the URL change resulted in an error, show the ng app
addGatewayListener('urlChangeError', ({ error }) => {
  // Log the error,
  // tslint:disable-next-line: no-console
  console.error(error);
  // but make sure angular is visible
  setAngularVisible(true);
  bootstrapNg();
});

export const createNgToMfeInterop = <Links extends object>(
  cookieName: string,
  createAppLinks: (root: Link) => Links
): MfeFactory<Links> => (rootLink) => ({
  links: createAppLinks(rootLink.root),
  mount({ location }) {
    // Set that we are about to leave the angular application
    isLeavingAngularApp = true;

    // Deleting the cookie and reloading the page to continue with react template load.
    document.cookie = `${deleteCookie(cookieName)}`;

    location.reload();

    // We need to indicate to the gateway that we are not done loading our MFE
    // We require an entirely new document load in order to finish loading our MFE,
    // So we return a promise that never finishes
    return new Promise(() => null);
  },
  unmount() {
    // noop
  }
});

export const createNgFactory = <Links extends object>(
  createAppLinks: (root: Link) => Links
): MfeFactory<Links> => (rootLink) => ({
  links: createAppLinks(rootLink.root),
  mount: bootstrapNg,
  unmount() {
    // noop
  }
});

export const createMfeRedirector = (
  url: string,
  replace = false
): MfeFactory<any> => () => ({
  links: null as any,
  mount({ location }) {
    if (replace) location.replace(url);
    else location.assign(url);
  },
  unmount() {
    // noop
  }
});
