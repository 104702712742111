// We register a fallback that ensures that if no mfes are loaded,
// we still load the angular app. If all MFEs were accounted for
// this will not happen, but we have to be sure to show the angular

import { addGatewayListener } from 'packages/gateway/gateway';
import { Link } from 'packages/links';

export const createNgFallbackHandler = (fallback: Link) => {
  let gatewayIsPendingUrlChange = false;
  let uiRouterGaveUpOnCurrentRoute = false;

  addGatewayListener('urlChangeStarted', () => {
    gatewayIsPendingUrlChange = true;
    uiRouterGaveUpOnCurrentRoute = false;
  });
  const handleGatewayFallback = () => {
    // Mark that the gateway url change is settled
    gatewayIsPendingUrlChange = false;
    if (uiRouterGaveUpOnCurrentRoute) {
      // Make a redirect now if the angular router has given up
      history.pushState(null, '', fallback.url());
    }
  };
  // Handle the gateway fallback
  addGatewayListener('urlChangeError', handleGatewayFallback);
  addGatewayListener('urlChangeCompleted', handleGatewayFallback);

  // Config function to run in Angular
  function $config($urlRouterProvider: any) {
    $urlRouterProvider.otherwise(function () {
      let redirect: boolean | string = false; // url or bool
      if (gatewayIsPendingUrlChange) {
        // If the gateway is pending a url change, then
        // we need to wait for it to finish and it will handle the fallback
        uiRouterGaveUpOnCurrentRoute = true;
      } else {
        // If the gateway has settled, then
        // we need to the handle the fallback ourselves
        redirect = fallback.url();
      }
      return redirect;
    });
  }
  $config.$inject = ['$urlRouterProvider'];

  return $config;
};
