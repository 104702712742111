import { getAcpConfig } from './global-acp';

export type Platform = 'ios' | 'android' | 'web';

export const derivePlatform = (): Platform => {
  const configPlatform: Platform = getAcpConfig().config.platform;
  // If the platform is already specified as not web, we should just go with what the config says
  if (configPlatform !== 'web') {
    return configPlatform;
  }

  const ua = (navigator.userAgent || '').toLowerCase();
  const match: string[] | null = ua.match(/cordova\/(ios|android)/);
  const platform: string = (match && match[1]) || '';

  if (platform === 'ios') {
    return platform;
  }
  if (platform === 'android') {
    return platform;
  }
  return 'web';
};

export const isWeb = (): boolean => derivePlatform() === 'web';

export const isCordova = (): boolean => !isWeb();
