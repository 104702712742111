import ng from 'angular';
import { displayApp, onException } from './exception-handler';
import {
  derivePlatform,
  getNativeDeviceVersion,
  getResourceBaseUrl,
  hasResourceBaseUrl,
  isCordova,
  overrideConfiguration,
  setupDeeplinking,
  setWebpackBaseUrl
} from './util';

// Export a simple func to be ES5 compliant. We cant do fun ESNext stuff until this bootstrap is called
export function doBootstrap(ngModules: any[]) {
  return (async () => {
    /*
     * Set Webpack base url to support loading from a CDN
     */
    if (hasResourceBaseUrl()) {
      // We have to tell webpack what the proper base-url is before we import() any async packages
      setWebpackBaseUrl(getResourceBaseUrl());
    }

    /*
     * If needed, wait for cordova to become ready
     */
    try {
      if (isCordova()) {
        await new Promise((resolve) =>
          document.addEventListener('deviceready', resolve)
        );
      }
    } catch (e) {
      // This is not a fatal exception, so do not return out of this init process
      onException(e, 'deviceready', false);
    }

    /*
     * We need to forcefully override the platform information so the app can bootstrap properly,
     * until we create a "cordova-hosted" platform type
     */
    if (isCordova()) {
      try {
        overrideConfiguration(
          derivePlatform(),
          'cordova', // TODO, should this be "cordova-hosted"? or something new?
          await getNativeDeviceVersion()
        );
      } catch (e) {
        // This is not a fatal exception, so do not return out of this init process
        onException(e, 'overrideconfiguration', false);
      }
    }

    /*
     * Wait for the dom to become ready
     */
    try {
      await new Promise((resolve) => ng.element(document).ready(resolve));
    } catch (e) {
      return onException(e, 'domready');
    }

    /**
     * Get Deeplink and send to appsflyer if available
     */
    try {
      setupDeeplinking();
    } catch (e) {
      // This is not a fatal exception, so do not return out of this init process
      onException(e, 'deeplink', false);
    }

    /*
     * Load the entry point angular module so we can bootstrap
     */
    let appModule: ng.IModule;
    try {
      appModule = (
        await import(
          /* webpackChunkName: "acp.app" */
          // @ts-ignore: This is an unknown alias due to crossing the angularjs ng-module and standard lib/bob builds
          'app'
        )
      ).default;
    } catch (e) {
      return onException(e, 'appload');
    }

    /*
     * Startup angular by bootstrapping to the dom
     */
    try {
      ng.bootstrap(document, [appModule.name, ...ngModules], {
        strictDi: true
      });
    } catch (e) {
      return onException(e, 'bootstrap');
    }

    /*
     * Display the app to the user if not already displayed
     */
    displayApp();
  })();
}
