import { isCordova, track } from './util';

declare global {
  interface Navigator {
    splashscreen?: {
      hide: () => void;
    };
  }
}

export const onException = (
  exception: ErrorEvent,
  code: string,
  isFatal = true
) => {
  if (isFatal) {
    showErrorBootstrap();
  }

  trackException(exception);
};

export const trackException = (exception: ErrorEvent) => {
  let message =
    (exception && exception.message) || exception || 'unknown exception';

  if (exception && exception.filename && exception.lineno) {
    message = `:${exception.filename}: ${exception.lineno}`;
  }

  track({
    event: 'nsException',
    nsException_exDescription: message,
    nsException_exFatal: true
  });
};

export const displayApp = () => {
  // Display the app if we are cordova and cannot retry without the cdn.
  if (isCordova() && navigator.splashscreen) {
    navigator.splashscreen.hide();
  }
};

export const showErrorBootstrap = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById('acp-error-bootstrap')!.style.display = 'block';
  displayApp();
};
