import { initializePGMListener } from 'apps/acp/packages/previous-gateway-mount';
import {
  baseUrl,
  createInjectable,
  declareMicroFrontend,
  start
} from 'packages/gateway/gateway';
import { AcpEnvironment } from 'apps/acp/packages/acp-config';
import { setPrivateAcpEnvOnce } from './environment';
import {
  createMfeRedirector,
  createNgFactory,
  createNgToMfeInterop
} from './ng-interop';
import { once } from './once';
import { declarativePermissionApi, isPreOnboardingEligible } from './services';

const logout = declareMicroFrontend('logout', baseUrl.extend`/logout`());
const activateCard = declareMicroFrontend(
  'activation',
  baseUrl.extend`/activate`()
);
const dashboard = declareMicroFrontend(
  'dashboard',
  baseUrl.extend`/dashboard`()
);
const moveMoney = declareMicroFrontend(
  'move-money',
  baseUrl.extend`/move-money-menu`()
);
const manageAccount = declareMicroFrontend(
  'your-account',
  baseUrl.extend`/your-account`()
);
const upgradeAccount = declareMicroFrontend(
  'upgrade-account',
  baseUrl.extend`/upgrade`()
);
const benefitCenter = declareMicroFrontend(
  'benefit-center',
  baseUrl.extend`/benefit-center`()
);
const spendingAccount = declareMicroFrontend(
  'spending-account',
  baseUrl.extend`/spending-account`()
);
const leanDispute = declareMicroFrontend(
  'lean-dispute',
  baseUrl.extend`/file-dispute`()
);

const login = declareMicroFrontend('login', baseUrl.extend`/login`());

const paypalAuthenticate = declareMicroFrontend(
  'paypal-authenticate',
  baseUrl.extend`/paypal/authenticate`()
);

const spendTracker = declareMicroFrontend(
  'spending-tracker',
  baseUrl.extend`/spending-tracker`()
);

const card = declareMicroFrontend('card', baseUrl.extend`/card`());

const directDeposit = declareMicroFrontend(
  'direct-deposit',
  baseUrl.extend`/direct-deposit`()
);

const directDepositTracker = declareMicroFrontend(
  'direct-deposit-tracker',
  baseUrl.extend`/dd-tracker`()
);

const debitCardTransferFunds = declareMicroFrontend(
  'transfer-funds',
  baseUrl.extend`/transfer-funds`()
);

const dormantAccount = declareMicroFrontend(
  'dormant-account',
  baseUrl.extend`/dormant-account`()
);

const register = declareMicroFrontend('register', baseUrl.extend`/register`());

const security = declareMicroFrontend('security', baseUrl.extend`/security`());

const recover = declareMicroFrontend('recover', baseUrl.extend`/recover`());

const preOnboarding = declareMicroFrontend(
  'pre-onboarding',
  baseUrl.extend`/pre-onboarding`()
);

const notifications = declareMicroFrontend(
  'notifications',
  baseUrl.extend`/notifications`()
);

const cashRewards = declareMicroFrontend(
  'cashrewards',
  baseUrl.extend`/cash-rewards`()
);

const directOffers = declareMicroFrontend(
  'directOffers',
  baseUrl.extend`/direct-offers`()
);

const disputeHubTracker = declareMicroFrontend(
  'disputeHubTracker',
  baseUrl.extend`/dispute-hub`()
);

const closeAccount = declareMicroFrontend(
  'close-account',
  baseUrl.extend`/close-account`()
);

const cashback = declareMicroFrontend('cashback', baseUrl.extend`/cashback`());

const contact = declareMicroFrontend('contact', baseUrl.extend`/contact`());

export const manage = declareMicroFrontend('manage', baseUrl.extend`/manage`());

const crypto = declareMicroFrontend('crypto', baseUrl.extend`/crypto`());

const uploadAdditionalDocuments = declareMicroFrontend(
  'upload-additional-documents',
  baseUrl.extend`/upload-additional-documents`()
);

const locations = declareMicroFrontend(
  'locations',
  baseUrl.extend`/locations`()
);

const locationFinder = declareMicroFrontend(
  'location-finder',
  baseUrl.extend`/location-finder`()
);

const atmFinder = declareMicroFrontend(
  'atm-finder',
  baseUrl.extend`/atm-finder`()
);

const dynamicFAQ = declareMicroFrontend(
  'dynamic-faq',
  baseUrl.extend`/dynamic-faq`()
);

const loyalty = declareMicroFrontend('loyalty', baseUrl.extend`/points`());

const esign = declareMicroFrontend('esign', baseUrl.extend`/esign`());

const secureUpload = declareMicroFrontend(
  'secure-upload',
  baseUrl.extend`/secure-upload`()
);

const handoff = declareMicroFrontend('handoff', baseUrl.extend`/handoff`());

const fraudDetection = declareMicroFrontend(
  'fraud-detection',
  baseUrl.extend`/fraud-detection`()
);

dashboard
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:rising_tide_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

leanDispute.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

paypalAuthenticate
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:login_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

login
  .implement(
    createInjectable(() => () =>
      createMfeRedirector('/account/pre-onboarding')
    ),
    createInjectable(
      () => ({ isPreOnboardingEligible }) => () => isPreOnboardingEligible,
      { isPreOnboardingEligible }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root,
        authorize: root.extend`/authorize`(),
        ooba: root.extend`/ooba`(),
        troobaOrderCardLink: root.extend`/trooba-order-card`()
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:login_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root,
        authorize: root.extend`/authorize`()
      }))
    )
  );

const activationMfeEligibilityCheckerInjectable = createInjectable(
  () => ({ permissions }) => async () => {
    return (
      ((await permissions({ 'webapi:reactivation_eligible': true })) ||
        location.href.includes('/activate/handoff') ||
        location.href.includes('/register/handoff') ||
        location.href.includes('/activate/cardless-handoff')) &&
      !location.href.includes('/activate/additional-card')
    );
  },
  {
    permissions: declarativePermissionApi
  }
);

activateCard
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    activationMfeEligibilityCheckerInjectable
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

register
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    activationMfeEligibilityCheckerInjectable
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

spendTracker
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:spending_tracker_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

card
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:cardlanding_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

directDeposit
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        !location.href.includes('/direct-deposit-form') &&
        (await permissions({
          'acp:directdeposit_mfe_eligible': true
        })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    // Because of a gateway bug, path /direct-deposit-form activates the direct-deposit MFE
    createInjectable(() => () =>
      createMfeRedirector('/account/direct-deposit/form-dialog', true)
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        location.href.includes('/direct-deposit-form') &&
        (await permissions({
          'acp:directdeposit_mfe_eligible': true
        })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeRedirector('/account/direct-deposit-form', true)
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        location.href.includes('/direct-deposit/form-dialog') &&
        !(await permissions({
          'acp:directdeposit_mfe_eligible': true
        })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

directDepositTracker
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ direct_deposit_tracker_enabled: true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

spendingAccount.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root,
      debit: root.extend`/debit`(),
      savings: root.extend`/savings`(),
      points: root.extend`/points`()
    }))
  ),
  createInjectable(
    () => ({ permissions }) => async () =>
      await permissions({ 'acp:rising_tide_eligible': true }),
    {
      permissions: declarativePermissionApi
    }
  )
);

moveMoney.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

manageAccount.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

benefitCenter
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:rt_benefits_center_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

upgradeAccount
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:upgrade_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root,
        activate: root.extend`/activate`(),
        learnMore: root.extend`/learn-more`()
      }))
    )
  );

security
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:security_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

logout.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

recover
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root,
        recoverUsername: root.extend`/username`(),
        recoverPassword: root.extend`/password`()
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:recover_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

debitCardTransferFunds
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'webapi:debit_card_transfer_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

preOnboarding
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:preonboarding_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

notifications.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

cashRewards
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

directOffers
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

cashback.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

disputeHubTracker
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'webapi:track_lean_dispute_enabled': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

dormantAccount
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'webapi:dormant_self_cure_enabled': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

closeAccount
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:close_account_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

contact
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:contact_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root,
        precontact: root.extend`/precontact`()
      }))
    )
  );

manage
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:contact_info_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

crypto.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

uploadAdditionalDocuments.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

locationFinder
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:locations_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

locations
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:locations_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

atmFinder.implement(
  createInjectable(() => () =>
    createNgFactory((root) => ({
      main: root
    }))
  )
);

dynamicFAQ
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:dynamic_faq_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

loyalty
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:loyalty_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

esign.implement(
  createInjectable(() => () =>
    createNgToMfeInterop('ACP_NG', (root) => ({
      main: root
    }))
  )
);

secureUpload
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:secure_upload_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

handoff
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root,
        handOffActivate: root.extend`/handoff-activate`()
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:handoff_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root,
        handOffActivate: root.extend`/handoff-activate`()
      }))
    )
  );

fraudDetection
  .implement(
    createInjectable(() => () =>
      createNgToMfeInterop('ACP_NG', (root) => ({
        main: root
      }))
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:handoff_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createNgFactory((root) => ({
        main: root
      }))
    )
  );

const startWithEnv = () => {
  setPrivateAcpEnvOnce(window.acp as AcpEnvironment);
  start();
  initializePGMListener();
};

export const startGateway = once(async () => startWithEnv());
