/*
 * This file is simply a type-safe and silent failing GTM API. It contains common functions to interact with the
 * GTM DataLayer that will not fail if GTM has not yet been loaded.
 */

export interface GtmDataLayer {
  push(data: any): void;
  pushValueCollectionToDataLayer?(key: string, val: string): void;
  loadConfig?(
    properties: string[],
    onSuccess: (properties: { [property: string]: string }) => void,
    onFailure: (message: string) => void
  ): void;
}

declare global {
  interface Window {
    dataLayer?: GtmDataLayer;
    adobeDataLayer?: any[];
  }
}

export const getDataLayer = (): GtmDataLayer => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return window.dataLayer!;
};

export const hasDataLayer = (): boolean => {
  return !!getDataLayer();
};

export const track = (data: any) => {
  if (hasDataLayer()) {
    getDataLayer().push(data);
  }
};

export const pushValueCollectionToDataLayer = (key: string, val: string) => {
  if (hasDataLayer() && getDataLayer().pushValueCollectionToDataLayer) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getDataLayer().pushValueCollectionToDataLayer!(key, val);
  }
};

export const loadConfig = (
  properties: string[]
): Promise<{ [property: string]: string }> => {
  return new Promise((resolve, reject) => {
    if (hasDataLayer() && getDataLayer().loadConfig) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getDataLayer().loadConfig!(properties, resolve, reject);
    } else {
      resolve();
    }
  });
};
